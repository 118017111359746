@media (max-width: 992px) {
  .playerBioContainer {
    margin-top: 2em;
    margin-left: 5rem;
  }
  .playerBioTitle {
    display: block;
  }
  .playerBioTitle ~ p {
    margin-left: 1rem;
  }
}

@media (min-width: 992px) {
  .playerBioTitle {
    display: none;
  }
}
