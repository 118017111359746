@media (max-width: 992px) {
  .playerDetailBanner {
    flex-direction: column;
  }
  .playerDetailBannerHeader {
    justify-content: space-between;
  }
}

@media (max-wdith: 576px) {
  .playerDetailName {
    text-align: center;
  }
}

@media (min-wdith: 576px) {
  .playerDetailName {
    text-align: end;
  }
}
