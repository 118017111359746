@media (max-width: 575.98px) {
  .percentNumberPosition {
    top: 7em;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .percentNumberPosition {
    top: 6em;
    left: 5.5em;
  }
  .percentNumber {
    font-size: 1.3em;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .percentNumberPosition {
    top: 9.5em;
    left: 8em;
  }
  .percentNumber {
    font-size: 1.3em;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .percentNumberPosition {
    top: 8em;
    left: 5.5em;
  }
  .percentNumber {
    font-size: 1.3em;
  }
}

@media (min-width: 1200px) {
  .percentNumberPosition {
    top: 9.5em;
    left: 6.5em;
  }
  .percentNumber {
    font-size: 1.5em;
  }
}
